exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-index-js": () => import("./../../../src/pages/aboutus/index.js" /* webpackChunkName: "component---src-pages-aboutus-index-js" */),
  "component---src-pages-blogs-index-jsx": () => import("./../../../src/pages/blogs/index.jsx" /* webpackChunkName: "component---src-pages-blogs-index-jsx" */),
  "component---src-pages-blogs-post-1-jsx": () => import("./../../../src/pages/blogs/post1.jsx" /* webpackChunkName: "component---src-pages-blogs-post-1-jsx" */),
  "component---src-pages-catalogues-index-js": () => import("./../../../src/pages/catalogues/index.js" /* webpackChunkName: "component---src-pages-catalogues-index-js" */),
  "component---src-pages-contactus-index-js": () => import("./../../../src/pages/contactus/index.js" /* webpackChunkName: "component---src-pages-contactus-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

